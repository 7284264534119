import { APIS } from '@/constants';
import axios from 'axios';

const walletApi = {
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestAddress(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/address',
      method: 'get',
      baseURL: APIS.WALLET,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestBalance(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/balance',
      method: 'get',
      baseURL: APIS.WALLET,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestTransfers(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/transfers',
      method: 'post',
      baseURL: APIS.WALLET,
      headers,
      data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {String} send_token_id
   * @returns {AxiosPromise}
   */
  requestTransferDetail(token, send_token_id) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: `/transfers/${send_token_id}`,
      method: 'get',
      baseURL: APIS.WALLET,
      headers,
    });
  },
};

export default walletApi;
