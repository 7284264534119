import Vue from 'vue';
import Vuex from 'vuex';

import keycloak from './keycloak';
import wallet from './wallet';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    keycloak,
    wallet,
  },
});
