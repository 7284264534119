import { AUTH, ROUTES } from '@/constants';
import { loadView } from '../index';

const HomeView = loadView('home/HomeView');

export const homeRoute = {
  path: ROUTES.PATH.HOME,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.HOME,
      component: HomeView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};
