const API_SERVICES = process.env.VUE_APP_API_SERVICES;
const API_VERSION = 'v1';
const BASE_PATH = `${API_SERVICES}/${API_VERSION}`;

const KEYCLOAK_PROVIDER_ALIAS = process.env.VUE_APP_KEYCLOAK_PROVIDER_ALIAS;
const KEYCLOAK_REALM = process.env.VUE_APP_KEYCLOAK_REALM;
const KEYCLOAK_URL = process.env.VUE_APP_KEYCLOAK_URL;
const KEYCLOAK_BASE_PATH = `${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}`;

export default Object.freeze({
  KEYCLOAK_PROVIDER: `${KEYCLOAK_BASE_PATH}/broker/${KEYCLOAK_PROVIDER_ALIAS}`,
  WALLET: `${BASE_PATH}/wallet`,
});
