<template>
  <v-app id="app">
    <v-container v-if="isLoading" fluid fill-height data-test="loading">
      <v-layout justify-center align-center>
        <v-progress-circular
          color="primary"
          indeterminate
          size="100"
          width="15"
        />
      </v-layout>
    </v-container>
    <v-container v-else-if="!isLoading && error" data-test="error">
      <v-alert dense outlined type="error">
        {{ error }}
      </v-alert>
    </v-container>
    <div v-else data-test="content">
      <router-view />
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapGetters('keycloak', ['error', 'isLoading']),
  },
};
</script>
