import { walletApi } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initHome({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchWallet')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchWallet({ commit, rootGetters }) {
    const token = rootGetters['keycloak/idpAccessToken'];
    try {
      const response = await walletApi.requestAddress(token);
      commit('setWallet', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async sendTokens({ commit, rootGetters }, data) {
    commit('setTransfer', null);
    const token = rootGetters['keycloak/idpAccessToken'];
    commit('setSending', true);
    try {
      const response = await walletApi.requestTransfers(token, data);
      commit('setTransfer', response.data);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setSending', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} sendTokenId
   */
  async fetchTransferDetail({ commit, rootGetters }, sendTokenId) {
    const token = rootGetters['keycloak/idpAccessToken'];
    try {
      const response = await walletApi.requestTransferDetail(
        token,
        sendTokenId,
      );
      commit('setTransfer', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
