import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setSending(state, payload) {
    state.isSending = payload;
  },
  setTransfer(state, payload) {
    state.transfer = payload;
  },
  setWallet(state, payload) {
    state.wallet = payload;
  },
};
