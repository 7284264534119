import { v4 as uuidv4 } from 'uuid';
import { COINS } from '@/constants';
import { tokensHandler } from '@/utils';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isSending: (state) => state.isSending,
  transfer: (state) => state.transfer,
  wallet: (state) => state.wallet,
  balance: (state) => {
    if (!state.wallet || !state.wallet?.coins.length) {
      return [];
    }
    return state.wallet.coins.reduce((acc, coin) => {
      const asset = COINS.ASSETS.find((asset) => asset.value === coin.denom);
      if (asset) {
        const amount = tokensHandler.convertFromBase(
          coin.amount,
          asset.exponent,
        );
        acc.push({
          id: uuidv4(),
          amount: amount.toFixed(2),
          decimals: asset.exponent,
          denom: coin.denom,
          symbol: asset.text,
        });
      }
      return acc;
    }, []);
  },
};
