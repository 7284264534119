import { AUTH, ROUTES } from '@/constants';
import { loadView } from '../index';

const IntroView = loadView('intro/IntroView');

export const introRoute = {
  path: ROUTES.PATH.INTRO,
  component: () => import('@/layouts/intro/IntroLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.ANY,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.INTRO,
      component: IntroView,
      meta: {
        auth: AUTH.USER_ROLE.ANY,
      },
    },
  ],
};
