import { APIS } from '@/constants';
import axios from 'axios';

const keycloakApi = {
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestProviderToken(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/token',
      method: 'get',
      baseURL: APIS.KEYCLOAK_PROVIDER,
      headers,
    });
  },
};

export default keycloakApi;
