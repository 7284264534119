import Keycloak from 'keycloak-js';

import { keycloakApi } from '@/apis';
import { AUTH } from '@/constants';

const initOptions = {
  url: AUTH.KEYCLOAK.URL,
  realm: AUTH.KEYCLOAK.REALM,
  clientId: AUTH.KEYCLOAK.CLIENT_ID,
};

const keycloak = new Keycloak(initOptions);

let refreshIntervalId;

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initAuth({ commit, dispatch }) {
    commit('setLoading', true);
    await dispatch('authenticate');
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async authenticate({ commit, dispatch }) {
    await dispatch('setupClient');
    if (keycloak.authenticated) {
      commit('setToken', keycloak.token);
      const requests = [
        dispatch('fetchIdpToken', keycloak.token),
        dispatch('fetchProfile'),
      ];
      await Promise.all(requests);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async setupClient({ commit, dispatch }) {
    try {
      await keycloak.init({
        onLoad: AUTH.KEYCLOAK.ON_LOAD.CHECK_SSO,
        checkLoginIframe: false,
      });
      refreshIntervalId = setInterval(async () => {
        await dispatch('refreshToken');
      }, AUTH.REFRESH_TOKEN_TIME * 1000);
    } catch (error) {
      commit('setToken', '');
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async refreshToken({ commit }) {
    try {
      const response = await keycloak.updateToken(70);
      if (response) {
        commit('setToken', keycloak.token);
      }
    } catch (error) {
      commit('setToken', '');
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {String} token
   */
  async fetchIdpToken({ commit }, token) {
    try {
      const response = await keycloakApi.requestProviderToken(token);
      commit('setIdpToken', response.data);
    } catch (error) {
      commit('setIdpToken', '');
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchProfile({ commit }) {
    try {
      const profile = await keycloak.loadUserProfile();
      commit('setProfile', profile);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async logOut({ commit }) {
    clearInterval(refreshIntervalId);
    try {
      await keycloak.logout();
      commit('reset');
    } catch (error) {
      commit('setError', error);
    }
  },
};
