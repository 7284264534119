import Vue from 'vue';
import VueRouter from 'vue-router';

import { AUTH, ROUTES } from '@/constants';
import navigationGuard from './navigation-guard';
import { homeRoute } from './routes/home-route';
import { introRoute } from './routes/intro-route';
import { notFoundRoute } from './routes/not-found-route';

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTES.PATH.ROOT,
    redirect: () => ROUTES.PATH.HOME,
    meta: {
      auth: AUTH.USER_ROLE.AUTHORIZED,
    },
  },
];

routes.push(homeRoute, introRoute);
routes.push(notFoundRoute);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.auth === AUTH.USER_ROLE.ANY) {
    next();
    return;
  }

  const userRole = await navigationGuard.setupAuthorization();
  if (userRole === AUTH.USER_ROLE.NOT_AUTHORIZED) {
    next(ROUTES.PATH.INTRO);
    return;
  } else {
    next();
    return;
  }
});

export default router;

export function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}
