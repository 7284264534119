const TX = Object.freeze({
  MESSAGE_STATUS: {
    ENQUEUED: 'enqueued',
    FAILURE: 'failure',
    PROCESSING: 'processing',
    SUCCESS: 'success',
  },
});

export default Object.freeze({
  TX,
});
