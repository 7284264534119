const PATH = Object.freeze({
  HOME: '/home',
  INTRO: '/intro',
  NOT_FOUND: '/:notFound(.*)',
  ROOT: '/',
});

const NAME = Object.freeze({
  HOME: 'home',
  INTRO: 'intro',
  NOT_FOUND: 'notFound',
});

export default Object.freeze({
  NAME,
  PATH,
});
